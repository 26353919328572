import Vue from "vue";
import VueRouter from "vue-router";

const Index = () => import("../views/IndexView.vue");
const Home = () => import("../views/HomeView.vue");
const Main = () => import("../views/MainView.vue");
const Set = () => import("../views/SetView.vue");
const User = () => import("../views/UserView.vue");
const Update = () => import("../views/UpdateView.vue");
const Test = () => import("../views/TestView.vue");
const Device = () => import("../views/DeviceView.vue");
const File = () => import("../views/FileView.vue");
const Setting = () => import("../views/SettingView.vue");

const Help = () => import("../views/HelpView.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/index" },
    {
      path: "/index", component: Index, children: [
        { path: "", redirect: "home" },
        { path: "home", component: Home },
        {
          path: "main", component: Main, children: [
            { path: "set", component: Set },
            { path: "user", component: User },
            { path: "update", component: Update },
            { path: "test", component: Test },
          ]
        },
        { path: "device", component: Device },
		{ path: "file", component: File },
        { path: "setting", component: Setting },
      ]
    },
    { path: "/help", component: Help }
  ],
});

router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.getItem("token");
  let valid = true;
  if (token == null) {
    valid = false;
  }

  if (to.path === "/index/setting" && !valid) {
    next("/index/home");
  } else {
    next();
  }
});

export default router;
