// 蓝牙连接类，结合发布订阅者进行操作

/*
  @Param filters 筛选条件
  @Param serverID 默认连接服务
  @Param readID 默认读取监听特征
  @Param sendID 默认写入特征
  @Param disconnected 断线执行函数
*/

import { Toast } from 'vant';

class BlueTooth {

  // 初始化蓝牙对象 基础数据
  constructor({ filters, serverID, readID, sendID, disconnected, handleNotifications }) {
    this.filters = filters;
    this.serverID = serverID;
    this.readID = readID;
    this.sendID = sendID;
	console.log("this.filters",this.filters);
	console.log("this.serverID",this.serverID);
	console.log("this.readID",this.readID)
    this.disconnected = disconnected;
    this.handleNotifications = handleNotifications;
    // 消息储存数组
    this.recv_list = [];
    // 创建订阅者
    this.deps = new Dep();
    // 创建代理
    this.$recv_list = this.proxyList();
  }

  // 监听收到的命令
  proxyList() {
    let _this = this;
    return new Proxy(this.recv_list, {
      set(target, prop, value) {
        value += ""
        // 通知订阅者干活
        _this.deps.notify(value.slice(4, 6), value);
        return Reflect.set(...arguments);
      }
    })
  }

  // 开始连接
  onConnect(callBack = null) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject();
      }, 15000);
      navigator.bluetooth.requestDevice(this.filters)
        .then(device => {
          console.log(device);
          this.device = device;
          if (callBack) {
            callBack(device);
          }
          // 连接蓝牙并监听断开
          device.addEventListener('gattserverdisconnected', this.onDisconnected.bind(this))
          return device.gatt.connect()
        })
        .then((server) => {
          // 储存server服务
          this.server = server;
          resolve();
        })
        .catch((err) => {
          console.error("connect error: ", err);
          if (this.disconnected) {
            this.disconnected();
          }
          reject(err);
        })
    })
  }

  // 连接服务
  onServer(serverID = this.serverID) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject();
      }, 15000);
      if (!this.server) {
        console.warn("蓝牙未连接");
        reject();
      }
      this.server.getPrimaryService(serverID)
        .then((service) => {
          // 存储service特征
          this.service = service;
          resolve();
        })
        .catch((err) => {
          console.error("server error: ", err);
          this.clearConnect();
          reject(err);
        })
    })
  }

  // 监听特征
  onRead(serviceID = this.readID) {
    if (!this.service) {
      console.warn("未连接蓝牙服务");
      return;
    }
    return new Promise((resolve, reject) => {
      this.service.getCharacteristic(serviceID)
        .then((characteristic) => {
          return characteristic.startNotifications()
        })
        .then((characteristic) => {
          // 改变this指向，不然会指向 事件对象
          characteristic.addEventListener('characteristicvaluechanged',
            this.onHandleNotifications.bind(this));
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error("read error: ", err);
          reject(err);
        })
    })
  }

  // 主动读取特征数据
  onReadInfo(readID = this.readID) {
    if (!this.service) {
      console.warn("未连接蓝牙服务");
      return;
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject();
      }, 3000)
      this.service.getCharacteristic(readID)
        .then((characteristic) => {
          return characteristic.readValue()
        })
        .then((value) => {
			console.log("read value:",value)
			resolve(hString(value));
        })
        .catch((err) => {
          console.error("readinfo error: ", err);
          reject(err);
        })
    })
  }

  // 写入特征
  onSend(str, sendID = this.sendID) {
    if (!this.service) {
      Toast.fail({
        message: '蓝牙未连接',
        forbidClick: true,
      });
      return;
    }
    return new Promise((resolve, reject) => {
      this.service.getCharacteristic(sendID)
        .then((characteristic) => {
          characteristic.writeValue(new Uint8Array(sHEX(str)))
        })
        .then(() => {
          setTimeout(() => {
            resolve();
          }, 0)
        })
        .catch((err) => {
          console.error("send error: ", err);
          reject(err);
        })
    })
  }

  // 发送 并等待消息返回
  SendInfoFn(str, time = 3000, sendID = this.sendID) {
    if (!this.service) {
      console.warn("未连接蓝牙服务");
      return;
    }
    return new Promise((resolve, reject) => {
      console.warn("send: ", str);
      this.service.getCharacteristic(sendID)
        .then((characteristic) => {
          // 添加订阅者
		  console.log("send: ",str)
          this.deps.addDep(
            str.slice(4, 6),
            new Watch(
              function (str) {
                resolve(str);
              }
            )
          )
          setTimeout(() => {
            reject();
          }, time)
          characteristic.writeValue(new Uint8Array(sHEX(str)))
        })
        .catch((err) => {
          console.error("send error: ", err);
          reject(err);
        })
    })
  }

  // 循环判断，判断是否发送成功
  async onSendInfo(str, numbers = 3, time = 3000, sendID = this.sendID) {
    let num = 0;
    while (num < numbers) {
      try {
        let string = await this.SendInfoFn(str, time, sendID);
        return new Promise((resolve) => {
          resolve(string)
        })
      } catch {
        num++;
      }
    }
    // 循环结束，抛出错误
    return new Promise((resolve, reject) => {
      reject(err)
    })
  }

  // 手动断开
  clearConnect() {
    if (!this.device) {
      console.warn("蓝牙未连接");
      return;
    }
    this.device.gatt.disconnect();
  }

  // 断开连接函数
  onDisconnected() {
    // 先执行用户传进来的断线函数
    if (this.disconnected) {
      this.disconnected();
    }
    this.device = null;
    this.server = null;
    this.service = null;
    this.dep = null;
    console.warn("蓝牙已断开")
  }

  // 消息处理函数
  onHandleNotifications(event) {
    // 解析
    let str = hString(event.target.value);
    // 接收到的消息就push进去
    this.$recv_list.push(str);
    if (this.handleNotifications) {
      this.handleNotifications(str);
    }
  }

}

// 发布者

class Dep {
  constructor() {
    this.deps = {}
  }

  /* 
    添加订阅者
    @Param key 订阅名称
    @Param dep 订阅者
  */
  addDep(key, dep) {
    this.deps[key] = dep;
  }

  /* 
    通知订阅者起床干活
    @Param key 订阅名称
    @Param str 数据
  */
  notify(key, str) {
    if (this.deps.hasOwnProperty(key)) {
      this.deps[key].updata(str);
    }
  }
}

// 订阅者

class Watch {
  constructor(callBack) {
    this.callBack = callBack;
  }

  updata(str) {
    this.callBack(str);
  }
}

// 数据解析函数

function hString(value) {
  let str = "";
  for (var i = 0; i < value.buffer.byteLength; i++) {
    str += ('00' + value.getUint8(i).toString(16)).slice(-2).toUpperCase()
  };
  return str;
}

function sHEX(str) {
  let arr = [];
  Number(str);
  for (let i = 0; i < str.length; i += 2) {
    arr.push(Number("0x" + str.slice(i, i + 2)));
  }
  return arr;
}

function dU16(str) {
  let bHex = ("0000" + (+str).toString(16)).slice(-4)
  return (bHex.slice(2, 4).toUpperCase() + bHex.slice(0, 2).toUpperCase())
}

export { BlueTooth, hString, sHEX, dU16 }