var Languague = {
  // 公共
  public: {
    notice: "Notice",
    Ble_err: "BLE Open Error:\n",
    public: "Connecting",
    home: "home",
    not_connect: "Not connect",
    connect_notice: "Connecting device",
    connect_err: "Connection fail:\n",
    connect_ed: "Connected",
    connect_end: "Disconnect",
    connect_fail: "BLE Connect Fail",
    connect_re: "ReConnect",
    connect_ble: "BLE Connect",
    define: "ok"
  },
  // 首页
  index: {
    top_title: "HS2300 Battery Test System",
    copy_right: "Battery test & modification V2.11",
    imgUrls: ["/img/bn01_en.jpg", "/img/bn02_en.jpg", "/img/bn03_en.jpg"],
  },
  // 列表
  home: {
    top_title: "Feature List",
    Feature_1: "PCBA Set", //排线设置
    Feature_2: "User Set", //用户管理
    Feature_3: "Upgrade", //排线升级
    Feature_4: "Battery Test", //电池测试
    Feature_5: "Param MOD", //参数修改
    Feature_6: "Device upgrade", //蓝牙盒升级
    Feature_7: "Installation", //帮助
	Feature_8: "Firmware get", //帮助
    PB_notice: "PCBA 11~13 series", //外接排线11~13系列
    BT_notice: "Battery 6~13 series",// 6G~13系列
    MOD_notice: "TI case 8~Xsmax", //原装方案8~Xsmax
    DEV_notice: "Device software update", //蓝牙盒升级提示
    help_notice: "PCBA installation & types", //帮助提示
  },
  //排线设置
  set: {
    top_title: "PCBA Setting",
    bt_type: "Type",   //电池型号
    designCapcity: "DCap",
    NCC: "NCC",
    QMAX: "QMAX",
    SOH: "SOH",
    Cycle: "Cycle",
    read: "Read Data",
    unset: "Reset",
    set: "Set",
    cycle_err: "Cycle limited to numbers <1500.",
    cycle_ok: "Set cycle succeeded.",
    cycle_timeout: "Set cycle timeout.",
    soh_err: "SOH needs: >50, and <200.",
    soh_ok: "SOH setting succeeded.",
    cancel_ok: "Reset succeeded.",
    cancel_err: "Reset fail",
    read_timeout: "Read param timeout.",
    mode_title: "PCBA Mode",
    mode_search: "Mode Search",
    mode_summary: "The default mode is normal. In demo mode, each power-on needs to be initialized, and the installation needs to be charged, started, and restarted to refresh the health.",
    mode_normal: "Normal Mode",
    mode_demo: "Demo Mode",
    mode_result: "Information",
    mode_demo_result: "Currently in demo mode.",
    mode_normal_result: "Currently in normal mode.",
    mode_error: "Cannot be used with this firmware version."
  },
  //文件下传
  file:{
  	top_title: "Firmware download", //固件下载
  	page_title: "Firmware list :",   //固件下载
	button_name: "Download",
	top_title2: "Device update",   //设备升级
	vercheck: "Check Ver",   //设备检测
	update: "Update",    //升级
	calitimes: "Time updated",    //校准时间
	latest: "Device version:",
	search_out: "Unable to find version.",
  },
  //用户管理
  user: {
    top_title: "User Set for PCBA",
    enter_PIN: "Please enter password:",
    search: "User Info Search",
    new_PIN: "new password",
    change: "update",
    new_Info: "User information",
    info_set: "update",
    PIN_notice: "Please enter the correct password.",
    PIN_success: "Password sets successfully.",
    PIN_fail: "Failed to set the password.",
    pcba_notice: "Can't be queried, check the PCBA.",
    PIN_check: "Cannot be unlocked. check the password",
    info_notice: "Not set yet. Info is empty.",
    info_err: "Failed to query Info",
    info_check: "Info is limited to 9 digits.",
    info_success: "Set successfully: ",
    info_fail: "Failed to set Info",
  },
  //升级
  UPD: {
    top_title: "PCBA upgrade",
    page_title: "PCBA upgrade",
    ver: "Version",  //版本
    size: "Size", //太小
    date: "Date",  //日期
    info: "Summary",  //说明
    type: "Model",  //型号
    PB11: "11 series", //11系列
    PB12: "12 series", //11系列
    PB13: "13 series", //11系列
    vercheck: "PCBA Check",   //排线检测
    update: "Upgrade",    //升级
    OI_title: "Operation instruction:",   //操作说明
    OI_content: "Please select PCBA model before upgrading.\nBefore upgrading, can check PCBA current version; \n unneeded to upgrade if it's already the latest.",
    ver_err: "Version not detected, can't upgrade.",
    pcba_err: "PCBA can't be detected.",
    pcba_ver: "Version: ",
    up_need: ", Can be upgraded.",
    up_notneed: ", No upgrade is required.",
    up_fail: "Upgrade failed",
    up_success: "Upgrade successful.",
    up_over: "Update Finished.",
    net_check: "Unable to down file, please check network.",
    up_err: "Failed，Please upgrade again.",
    mode_error: "Cannot be upgrade with this version."
  },
  //电池测试
  test: {
    top_title: "Battery Test",
    bat_type: "Battery name",  //电池型号
    param_name: "Data",  //参数名称
    value: "Value",     //参数值
    unit: "Unit",    //单位
    param_read: "Read Data",  //参数读取
    time_out: "Battery read timeout.",  //超时
    bat_err: "Timeout, please check battery.", //未检测到电池
  },
  //参数修改
  mod: {
    top_title: "Param modify",
    bt_type: "Type",   //电池型号
    designCapcity: "DCap",
    FCC: "FCC",
    Cycle: "Cycle",
    sn: "SN",
    read: "Read Data",
    modify: "Write Data",
    mod_notice: "Note: The original TI solution battery is required, and the battery voltage must be bigger than 3.2V then parameters can be modified.",
    mod_success: "Parameter modification successful.",
    FCC_notice: "FCC limit within 0.5 to 1.5 times of the original value.",
    Cycle_notice: "Cycles is limited within 1000 digits.",
    SN_notice: "SN only allows combinations of numbers and letters.",
    SN_len_title: "SN Length mismatch。",
    SN_len_notice: "The length of the SN is different from that of the original SN. Be sure to change it",
    unlock_fail: "Failed to unlock. Please try again.",
    noMOD_notice: "Parameters have not changed and do not need to be modified.",
    fail_try: " time out, please try again.",
  },
  //蓝牙盒升级
  dev: {
    top_title: "Device update",
    page_title: "Update HS2300 device",  //HS2300设备升级
    ver: "Version",  //版本
    size: "Size", //太小
    date: "Date",  //日期
    info: "Summary",  //说明
    vercheck: "Check Ver",   //设备检测
    update: "Dev Update",    //升级
    cmd_notice: "command",    //命令发送
    cmd_send: "Send",  //发送
    OI_title: "Operating instructions",   //操作说明
    OI_content: "'Check Ver' before the upgrade, and then 'Dev Update'",
    update_ok: "Device upgrade succeeded.",
    update_err: "Device upgrade fail.",
    latest: "The latest version, no need to upgrade",
    dev_ver: "Please upgrade the device：",
    search_out: "Unable to find device version information.",
    FCCerr: "Design Capacity、FCC  Read timeout",
    DeviceErr: "Device name read timeout",
    SoftwareErr: "Software version read timeout",
    SnErr: "sn read timeout",
  },
  // 帮助
  help: {
    top_title: "Installation Instructions",
    imgurl: "/img/help_en.jpg"
  },
  //7037命令行
  cmd_0B: [
    ["Design Capacity", "mAH", "1803", "U2"],
    ["Voltage", "mV", "0903", "U2"],
    ["Battery Current", "mA", "0A03", "I2"],
    ["State of Charge", "%", "0D03", "I2"],
    ["Average Current", "mA", "0B03", "I2"],
    ["Temperature", "°c", "0803", "°c"],
    //["Internal Temperature", "°c", "7503", "°c"],
    ["Cycle Count", "cts", "1703", "U2"],
    ["Remaining Cap.", "mAH", "0F03", "U2"],
    ["Full Charge Cap.", "mAH", "1003", "I2"],
    ["Normal Charge Cap.", "mAH", "A003", "U2"],
    ["Design Voltage", "mV", "1903", "U2"],
    ["Qmax Capacity", "mAh", "8403", "U2"],
    ["Run Time To Empty", "min", "1103", "U2"],
    ["Average Time To Full", "min", "1303", "U2"],
    ["Battery Status", "flg", "1603", "H2"],
    ["Battery SN", " ", "9111", "Str"]
  ],
  //546命令行
  cmd_55: [
    ["Battery state", " ", "0002", "H2", "000000"],
    ["Device name", " ", "0002", "H2", "000100"],
    ["Software version", " ", "0002", "H2", "000200"],
    ["Design Capacity", "mAH", "3C02", "U2"], //
    ["Voltage", "mV", "0802", "U2"], // 
    ["State of Charge", "%", "2C02", "I2"],   //
    ["Cycle Count", "cts", "2A02", "U2"], //
    ["Remaining Capacity", "mAH", "1002", "U2"], //
    ["Full Charge Cap.", "mAH", "1202", "I2"],//
    ["Nominal Charge Cap.", "mAH", "7202", "U2"],//
    ["Qmax Capacity", "mAh", "2202", "U2"],  //
    ["Average Current", "mA", "1402", "I2"], //
    ["Temperature", "°c", "0602", "°c"],  //
    //["Internal Temp.", "°c", "2002", "°c"],  //
    ["OCV Voltage", "mV", "2802", "I2"],
    ["Time to Empty", "Min", "1602", "U2"],
    ["Battery SN", " ", "4014", "Str", "3F02"]
  ]
};


module.exports = {
  lang: Languague
}