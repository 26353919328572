import "./assets/css/style.css";
import Vue from "vue";
import router from "./router";
import store from "./store";

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import { hString, sHEX, dU16 } from "./utils/blueTooth";
Vue.prototype.$hString = hString;
Vue.prototype.$sHEX = sHEX;
Vue.prototype.$dU16 = dU16;

import http from "./utils/request";
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");
