import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import en_lang from "@/assets/language/en_lang";
import zh_lang from "@/assets/language/zh_lang";

import help_cn from "../assets/img/help_cn.jpg";
import help_en from "../assets/img/help_en.jpg";

export default new Vuex.Store({
  state: {
    // 语言环境
    language: zh_lang.lang,
    // 蓝牙对象
    BLE: null,
    help: help_cn,
    lang: "EN",
  },
  getters: {},
  mutations: {
    setLanguage(state, language) {
      state.lang = language;
      if (language === "CN") {
        state.language = en_lang.lang;
        state.help = help_en;
      } else if (language === "EN") {
        state.language = zh_lang.lang;
        state.help = help_cn;
      }
    },
    setBLE(state, ble) {
      state.BLE = ble;
    },
  },
  actions: {},
  modules: {},
});
