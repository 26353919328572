var Languague = {
  // 公共
  public: {
    notice: "提示",
    Ble_err: "打开蓝牙适配器失败",
    public: "设备连接中",
    home: "主页",
    not_connect: "未连接",
    connect_err: "连接失败",
    connect_ed: "已连接",
    connect_end: "断开连接",
    connect_fail: "蓝牙未连接",
    connect_re: "重连蓝牙",
    connect_ble: "连接蓝牙",
    define: "确认"
  },
  // 首页
  index: {
    //2.10权限增加，非开放设备不显示电池参数修改功能
    //2.12启动CRC8算法
    top_title: "HS2300电池测试V2.12",
    copy_right: "HS2300电池测试修改工具V2.12",
    imgUrls: ["/img/bn01_cn.jpg", "/img/bn02_cn.jpg", "/img/bn03_cn.jpg"],
  },
  // 列表
  home: {
    top_title: "功能列表",
    Feature_1: "排线设置", //排线设置
    Feature_2: "用户管理", //用户管理
    Feature_3: "排线升级", //排线升级
    Feature_4: "电池测试", //电池测试
    Feature_5: "参数修改", //参数修改
    Feature_6: "设备升级", //蓝牙盒升级
    Feature_7: "使用说明", //使用说明
	Feature_8: "固件下载", //固件下载
    PB_notice: "外接排线11~13", //外接排线11~13系列
    BT_notice: "电池6G~13系列",// 6G~13系列
    MOD_notice: "原装方案8~Xsmax", //原装方案8~Xsmax
    DEV_notice: "蓝牙盒固件升级", //蓝牙盒升级提示
    help_notice: "外接排线安装及适配表", //帮助提示
  },
  //排线设置
  set: {
    top_title: "外接排线设置",
    bt_type: "电池型号",   //电池型号
    designCapcity: "设计容量",
    NCC: "NCC",
    QMAX: "QMAX",
    SOH: "健康度",
    Cycle: "循环次数",
    read: "参数读取",
    unset: "取消设置",
    set: "设置",
    cycle_err: "循环错误，限<1500的数字",
    cycle_ok: "循环设置成功",
    cycle_timeout: "循环设置超时",
    soh_err: "健康度需：>50,且<200",
    soh_ok: "健康度设置成功。",
    cancel_ok: "取消设置成功",
    cancel_err: "取消设置失败",
    read_timeout: "读取参数超时",
    mode_title: "PCBA模式",
    mode_search: "模式查询",
    mode_summary: "默认为正常模式；演示模式下每次上电需初始化，装机需充电开机并重启刷新健康度。",
    mode_normal: "正常模式",
    mode_demo: "演示模式",
    mode_result: "提示",
    mode_demo_result: "当前为演示模式",
    mode_normal_result: "当前为正常模式",
    mode_error: "此固件版本，无法使用此功能",
  },
  //文件下传
  file:{
	top_title: "固件下载", //固件下载
	page_title: "固件列表",   //固件下载
	button_name: "固件下载",   //固件下载
	top_title2: "设备升级",   //设备升级
	vercheck: "版本检测",   //设备检测
	devVersion: "在线版本",    //设备线下版本
	update: "设备升级",    //升级
	calitimes: "时间同步",    //校准时间
	latest: "设备版本为：",
	search_out: "查询不到设备版本信息。",
	
  },
  //用户管理
  user: {
    top_title: "用户信息",
    enter_PIN: "请输入密码",
    search: "信息查询",
    new_PIN: "新密码",
    change: "密码修改",
    new_Info: "用户信息",
    info_set: "信息设置",
    PIN_notice: "请输入正确密码",
    PIN_success: "密码设置成功。",
    PIN_fail: "密码设置失败。",
    pcba_notice: "无法查询，外接排线连接异常。",
    PIN_check: "无法解锁，请确认密码正确。",
    info_notice: "暂未设置，信息为空。",
    info_err: "信息查询失败。",
    info_check: "内容限制9位内字母数字。",
    info_success: "设置成功：",
    info_fail: "信息设置失败。",
  },
  //升级
  UPD: {
    top_title: "外接排线升级",
    page_title: "外接排线升级",
    ver: "软件版本",  //版本
    size: "软件大小", //太小
    date: "更新日期",  //日期
    info: "更新说明",  //说明
    type: "排线型号",  //型号
    PB11: "11系列", //11系列
    PB12: "12系列", //11系列
    PB13: "13系列", //11系列
    vercheck: "排线检测",   //排线检测
    update: "排线升级",    //升级
    OI_title: "操作说明：",   //操作说明
    OI_content: "请先选择排线的型号，再进行升级。升级前可以检测排线的当前版本，最新版则不用升级。",
    ver_err: "未检测到版本，无法升级。",
    pcba_err: "无法检测到排线版本。",
    pcba_ver: "排线版本为:",
    up_need: ",可以升级。",
    up_notneed: ",不需要升级。",
    up_fail: "升级失败",
    up_success: "升级成功！",
    up_over: "升级完成。",
    net_check: "无法下载文件，请检查手机网络。",
    up_err: "升级通讯超时，请重新升级。",
    mode_error: "此固件版本，无法使用此功能",
  },
  //电池测试
  test: {
    top_title: "电池测试",
    bat_type: "电池名称",  //电池型号
    param_name: "参数名称",  //参数名称
    value: "参数值",     //参数值
    unit: "单位",    //单位
    param_read: "参数读取",  //参数读取
    time_out: "读取数据超时",  //超时
    bat_err: "未读到电池参数，请检查电池并重试。",
  },
  //参数修改
  mod: {
    top_title: "8G-Xsmax修改",
    bt_type: "电池型号",   //电池型号
    designCapcity: "设计容量",
    FCC: "FCC",
    Cycle: "循环次数",
    sn: "SN",
    read: "参数读取",
    modify: "参数修改",
    mod_notice: "备注: 需TI原装方案电池，且电池电压需大于3.2V才能操作参数修改。",
    mod_success: "参数修改成功。",
    FCC_notice: "FCC容量限原值0.5~1.5倍内",
    Cycle_notice: "循环次数限<1000数字。",
    SN_notice: "SN仅允许数字和字母组合。",
    SN_len_title: "SN长度不匹配",
    SN_len_notice: "SN长度与原SN不一致，确定要修改",
    unlock_fail: "解锁失败，请重试。",
    noMOD_notice: "参数未改变，不需修改。",
    fail_try: "修改超时，请重试",
  },
  //蓝牙盒升级
  dev: {
    top_title: "蓝牙盒升级",
    page_title: "HS2300设备升级",  //HS2300设备升级
    ver: "软件版本",  //版本
    size: "软件大小", //太小
    date: "更新日期",  //日期
    info: "更新说明",  //说明
    vercheck: "版本检测",   //设备检测
    update: "升级2300",    //升级
    cmd_notice: "命令行",    //命令发送
    cmd_send: "发送",  //发送
    OI_title: "操作说明",   //操作说明
    OI_content: "一、2300盒子亮绿灯情况下，须确认“蓝牙已连接”；\n二、2300盒子闪红灯情况下，可直接进行检测升级；\n三、升级前“设备检测”，再点击“升级2300”。",
    update_ok: "设备升级成功",
    update_err: "设备升级失败",
    latest: "设备为最新版本，不需升级：",
    dev_ver: "设备非最新版，请升级：",
    search_out: "查询不到设备版本信息，请联系您的供应商。",
    FCCerr: "设计、满充容量  读取超时",
    DeviceErr: "器件名  读取超时",
    SoftwareErr: "软件版本 读取超时",
    SnErr: "sn 读取超时",
  },
  // 帮助
  help: {
    top_title: "使用说明",
    imgurl: "/img/help_cn.jpg"
  },
  //自定义tarbar工具栏
  //7037命令行
  cmd_0B: [
    ["设计容量", "mAH", "1803", "U2"],
    ["电池电压", "mV", "0903", "U2"],
    ["电池电流", "mA", "0A03", "I2"],
    ["电池电量", "%", "0D03", "I2"],
    ["平均电流", "mA", "0B03", "I2"],
    ["电池温度", "°c", "0803", "°c"],
    //["内部温度", "°c", "7503", "°c"],
    ["循环次数", "cts", "1703", "U2"],
    ["剩余容量", "mAH", "0F03", "U2"],
    ["满充容量FCC", "mAH", "1003", "I2"],
    ["电池容量NCC", "mAH", "A003", "U2"],
    ["设计电压", "mV", "1903", "U2"],
    ["化学容量Qmax", "mAh", "8403", "U2"],
    ["放空时间", "min", "1103", "U2"],
    ["充满时间", "min", "1303", "U2"],
    ["电池状态位", "flg", "1603", "H2"],
    ["序例码SN", " ", "9111", "Str"]
  ],
  //546命令行
  cmd_55: [
    ["电池状态", " ", "0002", "H2", "000000"],
    ["器件名", " ", "0002", "H2", "000100"],
    ["软件版本", " ", "0002", "H2", "000200"],
    ["设计容量", "mAH", "3C02", "U2"], //
    ["电压", "mV", "0802", "U2"], // 
    ["电量", "%", "2C02", "I2"],   //
    ["循环次数", "cts", "2A02", "U2"], //
    ["剩余容量", "mAH", "1002", "U2"], //
    ["满充容量FCC", "mAH", "1202", "I2"],//
    ["电池容量NCC", "mAH", "7202", "U2"],//
    ["化学容量Qmax", "mAh", "2202", "U2"],  //
    ["平均电流", "mA", "1402", "I2"], //
    ["电池温度", "°c", "0602", "°c"],  //
    //["内部温度", "°c", "2002", "°c"],  //
    ["开路电压OCV", "mV", "2802", "I2"],
    ["放空时间", "Min", "1602", "U2"],
    ["序例码SN", " ", "4014", "Str", "3F02"]
  ]
};


module.exports = {
  lang: Languague
}