import Vue from "vue";
import axios from "axios";

const vm = Vue.prototype;

// 1. 创建axios实例
const http = axios.create({
  baseURL: "https://online.hiyunda.com:8181",
  // baseURL: "http://localhost:8181",
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 3. 设置请求拦截器
http.interceptors.request.use(function (config) {
  return config;
});

// 4. 设置响应拦截器
http.interceptors.response.use(function (response) {
  return response.data;
});

// 5. 导出http实例
export default http;
